@import 'node_modules/@wame/blue-oval-theme/webcore/variables.scss';
@import 'node_modules/@wame/blue-oval-theme/webcore/fonts';

$FDS-uniting-blue : red !important;

body{
	max-width: none !important;
	font-size: small;
}

.p-button-label{
	font-size: small;
}

.p-menuitem-text {
	font-size: small!important;
}

#app-menubar .p-menubar {
	background-color: #00095b;
	padding: 0.25rem;
}

#app-menubar .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link .p-menuitem-text {
	color: white;
}

#app-menubar .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link.p-menuitem-link-active {
	background-color: #00095b;
}

main, #app-content {
	padding: 1em 2em;
}

.p-datatable .p-datatable-tbody > tr > td {
	padding: 0.25rem;
}

.p-datatable .p-datatable-thead > tr > th {
	padding: 0.9em;
}

.p-datatable .p-datatable-tfoot > tr > td {
	padding: 0.25rem;
}

.p-inputtext {
	font-size: 0.9rem;
	padding: 0.25rem 0.25rem;
}

.p-multiselect .p-multiselect-label {
	padding: 0.25rem 0.25rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
	padding: 0.25rem 0.5rem;
	text-align: left;
}

.p-component {
	font-size: 0.9rem;
}

label {
	display: block;
	padding-bottom: 6px;
	color: #656868;
	font-weight: 100;
	width: max-content;
}

.self-end{
	align-self: self-end;
}

input[type="number"]{
	padding: 0.25rem 0.25rem;
}

input[type="text"]{
	padding: 0.25rem 0.25rem;
}

.p-button{
	padding:0.32rem 1rem;
}

.info-ford{
	padding: 1em;
	display: inline-block;
	margin: 1px 1px 20px 2px;
	font-family: sans-serif;
}

.p-fieldset {
	border: 1px solid #dee2e6;
	background: #fff;
	color: #4d4d4d;
	border-radius: 3px;
	padding: 2% 2% 1%;
}
